import { parsePrice, requestAdHoc } from './utils';
import constants from '../config/constants';

const localMessagingServiceUrl = `https://agent.sporttia.com:9001`;

export default class Cashdro {
    deviceIp = '';
    deviceUrl = '';
    user = '';
    pass = '';

    constructor(ip = '', user = 'support', pass = 'sporttia.1') {
        if (!ip) {
            this.deviceIp = localStorage.getItem('CashdroIp');
        } else {
            this.deviceIp = ip;
        }

        console.log('Device IP:', this.deviceIp);

        this.user = user;
        this.pass = pass;
        this.deviceUrl = `https://${this.deviceIp}/Cashdro3WS/index.php`;
    }

    async startOperation(type, params) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'startOperation',
                type: type,
                name: this.user,
                password: this.pass,
                parameters: params ? JSON.stringify(params) : '',
            },
        });

        /*
        {
          "code": <code>, si su valor es 1, no se ha producido error, en caso contrario se indica el código del error (valor negativo).
          -1 Usuario/password incorrectos.
          -2 CashDro ocupado con otra transacción.
          -3 Importe de la transacción incorrecto.
          -4 El usuario no tiene permiso para realizar la transacción.
          -99 Parámetros incorrectos.
          -1900 CashDro está cargando o actualizando.
          -998 CashDro está fuera de servicio. Revisar estado en la pantalla de diagnostico.
          -999 El servicio de CashDro está parado.
          "response": {
            "errorMessage": "none",
            "operation": {
              "operationId": "<operationId>"
            }
          }
        }

        LO DE ARRIBA ES MENTIRA LOL

        La respuesta real es tipo:
        {
            "code": 1,
            "data: "35" <- el operationId de verdad
        }

        PARECE QUE TODAS LAS RESPUESTAS DE LA DOC SON MENTIRA. SIEMPRE ES "data"

        */

        return result;
    }

    async confirmOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'acknowledgeOperationId',
                operationId,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code": <code>,
            1 ok
            -1 Usuario/password incorrectos
            -2 Identificador de la transacción incorrecto
            -3 Parámetros incorrectos, transacción no iniciada.
            "response": {
                "errorMessage": "none"
            }
        }
        */

        console.log(result);

        return result;
    }

    async queryOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'askOperation',
                operationId,
                name: this.user,
                password: this.pass,
            },
        });

        console.log(result);

        return result;

        /*
        {
          "code": <code>,
          "response": {
            "errorMessage": "none",
            "operation": {
              "operation": {
                "operationid": "<operationId>",
                "state": "<state>",
                    I = operación pendiente de ejecutar (acknowledge).
                    Q = la operación está en cola.
                    E = la operación está en ejecución.
                    F = la operación está finalizada.
                "payInProgress": "<payInProgress>",
                "payOutProgress": "<payOutProgress>",
                "total": "<total>",
                "totalin": "<totalin>",
                    Importe total introducido en CashDro. Siempre se indica multiplicado por 100. Por ejemplo, para un importe de 1,05 se indica 105.
                "totalout": "<-totalout>",
                    Importe total dispensado por CashDro. Siempre se indica multiplicado por 100 y en negativo.
                "amountchangenotavailable": "<amountchangenotavailable>"
                    Importe no devuelto al generarse un cambio no disponible.
                …
              },
              "devices": [
                ...
                {
                  "type": "<type>",
                  ...
                  "pieces": [
                    {
                      ...
                      "value": "<value>",
                      ...
                      "finishlevelrecycler": <finishlevelrecycler>,
                      "finishlevelcassette": <finishlevelcassette>,
                      ...
                    },
                    ...
                  ]
                },
                ...
              ],
              "messages": [],
              ...
              "withError": "<withError>",
                True/false. Indica si CashDro está operativo o en estado de error (ej . billete atascado,..).
              ...
            }
          }
        }
        */
    }

    async cancelOperation(operationId) {
        const result = await requestAdHoc('POST', localMessagingServiceUrl, {
            method: 'GET',
            url: this.deviceUrl,
            params: {
                operation: 'finishOperation',
                operationId,
                type: 2,
                name: this.user,
                password: this.pass,
            },
        });

        /*
        {
            "code":<code>,
            "response":{
                "errorMessage":"none"
            }
        }
        */

        return result;
    }
}
