import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../sporttia/AppContext';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { formatPrice } from '../../lib/utils';
import Cashdro from '../../lib/Cashdro';
import constants from '../../config/constants';

// global al módulo
const cashdroService = new Cashdro();
let interval;

export default function CashdroControlDialog({
    open,
    amount,
    onClose,
    onSuccess,
}) {
    const cxt = useContext(AppContext);

    const [operationId, setOperationId] = useState(null);
    const [operationOngoing, setOperationOngoing] = useState(false);

    const onAccept = async () => {
        setOperationOngoing(true);

        let result = await cashdroService.startOperation(
            constants.cashdro.op.SALE,
            {
                amount: amount * 100,
            } /* El cashdro representa las perras en plan 550 = 5,50 € */,
        );

        if (result.code === 1) {
            const opId = result.data;

            if (opId) {
                setOperationId(opId);

                result = await cashdroService.confirmOperation(opId);

                if (result.code === 1) {
                    interval = setInterval(async () => {
                        result = await cashdroService.queryOperation(opId);

                        const { code } = result;

                        // result.data viene como un string que representa un json
                        const data = JSON.parse(result.data);

                        console.log(code);
                        console.log(data);

                        const { operation } = data;

                        if (code === 1) {
                            // consulta correcta?
                            switch (operation?.state) {
                                case 'F': // fin, pagado
                                    console.log('Operación completada');
                                    clearInterval(interval);
                                    onSuccess();
                                    break;
                                // hay que esperar
                                case 'Q':
                                case 'E':
                                    console.log('Operación en curso...');
                                    // <Debug>
                                    /* await cashdroService.cancelOperation(
                                        operationId,
                                    );
                                    clearInterval(interval);
                                    onSuccess(); */
                                    // </Debug>
                                    break;
                                case 'I': // Operación no confirmada con acknowledgeOperation
                                default: // no puede pasar en teoría
                                    console.log(
                                        'Operación no confirmada / caso desconocido',
                                    );
                                    clearInterval(interval);
                                    break;
                            }
                        } else {
                            clearInterval(interval);
                        }
                    }, 3000);
                } else {
                    cxt.showMessage('E', `Error: ${result.data}`);
                }
            } else {
                cxt.showMessage(
                    'E',
                    'Error: no se pudo recuperar "operationID"',
                );
            }
        }
    };

    const onCancel = async () => {
        if (operationOngoing) {
            clearInterval(interval);

            const result = await cashdroService
                .cancelOperation(operationId)
                .catch((e) => {
                    cxt.showMessage('E', e.message);
                });

            if (result.code === 1) {
                cxt.showMessage('E', 'Cancelado!');
            }
        }
        onClose();
    };

    const title = 'Pago mediante CashDro';

    return (
        <Dialog open={open} onClose={onClose}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {operationOngoing ? (
                    <Box>{`Esperando al dispositivo...`}</Box>
                ) : (
                    <Box>
                        {`Va a enviar al dispositivo una orden de cobro por valor de ${formatPrice(
                            amount,
                            '€',
                        )}`}
                    </Box>
                )}
                {operationOngoing && (
                    <Box display="flex" justifyContent="center" mt={2} mb={2}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {operationOngoing ? (
                    <Button
                        onClick={onCancel}
                        color="primary"
                        variant="contained"
                        className="button-delete"
                    >
                        abortar operación
                    </Button>
                ) : (
                    <Button
                        onClick={onAccept}
                        variant="contained"
                        color="primary"
                    >
                        {cxt.t('Accept')}
                    </Button>
                )}
                <Button onClick={onCancel} variant="contained" color="default">
                    {cxt.t('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
