import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Paper, CircularProgress } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CheckOutlined, CloudDownload } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import {
    AppContext,
    SttDialog,
    SttDropdownButton,
    SttFormDate,
    SttFormText,
    SttFormTextarea,
    SttSelectPForm,
    SttSelectUser,
    SttTable,
} from '../../sporttia/all';
import { useCrud, useInteractionsFiles } from '../../lib/hooks';
import Consumption from './boletoDialog/Consumption';
import HandlePaymentsModal from '../../pages/sc/groups/HandlePaymentsModal';
import POSPaymentDialog from './POSPaymentDialog';
import POSStripePaymentDialog from '../stripe/POSStripePaymentDialog';
import useBonosService from '../../services/BonosService';
import { getErrorMessage } from '../../lib/utils';
import constants from '../../config/constants';
import translations from '../../translations';
import colors from '../../styles/Colors';
import CashdroControlDialog from './CashdroControlDialog';

const useStyles = makeStyles({
    playButton: {
        '&:hover': {
            cursor: 'pointer',
            color: 'rgba(33, 150, 243, 0.5)',
        },
    },
});

export default function BoletoDialog({ item, onSave, onClose }) {
    const cxt = useContext(AppContext);
    const { openFile } = useInteractionsFiles();
    const classes = useStyles();

    const [boleto, setBoleto, setProperty, Get, Post] = useCrud(item);
    const [movements, setMovements] = useState();
    const [price, setPrice] = useState();
    const [paymentForm, setPaymentForm] = useState();
    const [openAskingPaymentProcedure, setOpenAskingPaymentProcedure] =
        useState(false);

    const [posPaymentParams, setPosPaymentParams] = useState(null);

    const rowsPerPage = 5;

    function loadMovements(params = { page: 1, rows: rowsPerPage }) {
        const finalParams = {
            ...params,
            trash: !!boleto.trash,
        };
        Get(`/bonos/boletos/${item.id}/movs`, finalParams).then(setMovements);
    }

    const [cashdroPayment, setCashdroPayment] = useState(false);
    const [cashdroPaymentAmount, setCashdroPaymentAmount] = useState(0);

    // TODO: config pool CASHDRO
    const CASHDRO = cxt?.sc?.id === 1516 || cxt?.sc?.id === 3444;

    useEffect(() => {
        if (item.bono || item.payment) {
            setBoleto(item);
            if (item.id) {
                loadMovements();
            }
            setPrice(item.payment ? item.payment.price : item.bono.price);
            setPaymentForm(item.payment ? item.payment.paymentForm : '');
        } else {
            setBoleto({
                id: null,
                bono: {
                    id: item.id,
                },
            });
            setPrice(item.price);
        }
        // Migrar a react-query
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function saveAndClose() {
        onSave?.();
        onClose?.();
    }

    const bonosService = useBonosService();
    const updateBonoBoletoMutation = bonosService.useUpdateBonoBoleto({
        onSuccess: () => {
            saveAndClose();
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });
    const createBonoUserMutation = bonosService.useCreateBonoUser({
        onSuccess: (response) => {
            const { tpv } = response;

            if (
                tpv?.payment?.paymentForm ===
                constants.payment.paymentForms.dataphoneConnected.name
            ) {
                // params for the POS dialog (datáfono físico)
                setPosPaymentParams({
                    amount: tpv.price,
                    concept: tpv.concept,
                    idTpv: tpv.id,
                    sc: {
                        id: tpv?.sc?.id,
                        scName: tpv?.sc?.short,
                        entityName: tpv?.sc?.customer?.name,
                        address: tpv?.sc?.address,
                        cif: tpv?.sc?.customer?.cif,
                        phone: tpv?.sc?.phonePublic,
                    },
                });
            } else {
                saveAndClose();
            }
        },
        onError: (error) => {
            cxt.showMessage('E', getErrorMessage(error));
        },
    });

    function upsert() {
        if (boleto.id) {
            updateBonoBoletoMutation.mutate({
                id: boleto.id,
                params: {
                    notes: boleto.notes,
                },
            });
        } else {
            setupPayment();
        }
    }

    const setupPayment = () => {
        if (
            CASHDRO &&
            paymentForm === constants.payment.paymentForms.cashdro.name
        ) {
            setCashdroPayment(true);
            setCashdroPaymentAmount(price);
        } else {
            createPayment();
        }
    };

    const createPayment = () => {
        createBonoUserMutation.mutate({
            bonoId: boleto.bono.id,
            userId: boleto.user.id,
            params: {
                paymentForm,
                price,
                expiration: boleto.expiration,
                notes: boleto.notes,
            },
        });
    };

    const deleteBoleto = (deletingPaymentProcedure) => {
        let params;
        if (deletingPaymentProcedure !== undefined) {
            params = {
                managePayments: deletingPaymentProcedure,
            };
        }
        cxt.api('DELETE', `/bonos/boletos/${boleto.id}`, {
            params,
            success: () => {
                onClose();
                setOpenAskingPaymentProcedure(false);
                cxt.showMessage('S', cxt.t('Deleted'));
            },
        });
    };

    function consume() {
        Post(`/bonos/boletos/${boleto.id}/movs`, { consumptions: 1 }, true)
            .then(({ bonoMov }) => {
                setMovements({
                    ...movements,
                    count: movements.count + 1,
                    rows: [bonoMov].concat(movements.rows),
                });
                setProperty({ name: 'consumed', value: boleto.consumed - 1 });
            })
            .catch(() => {
                // catch but ignore
            });
    }

    function openReceipt(format) {
        if (item.payment) {
            openFile(
                `/payments/${item.payment.id}.pdf?format=${format}`,
                'application/pdf',
                null,
                `${cxt.t('Ticket')}_${item.payment.id}`,
            );
        }
    }

    function refundBonoMov(movId) {
        cxt.api('DELETE', `/bonos/movs/${movId}`, {
            confirmation: true,
            success: () => {
                boleto.consumed += 1;
                loadMovements();
            },
        });
    }

    if (
        createBonoUserMutation.isLoading ||
        updateBonoBoletoMutation.isLoading
    ) {
        return (
            <SttDialog
                maxWidth="md"
                open
                content={
                    <Box display="flex" justifyContent="center" paddingY={15}>
                        <CircularProgress />
                    </Box>
                }
            />
        );
    }

    return (
        <>
            <SttDialog
                open={!!item}
                maxWidth="md"
                title={cxt.t(translations.generic.bono)}
                onClose={onClose}
                content={
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SttSelectUser
                                controlDisabled={!!boleto.id}
                                name="user"
                                caption={cxt.t(translations.generic.name)}
                                user={boleto.user}
                                onSelect={(mship) => {
                                    setProperty({
                                        name: 'user',
                                        value: {
                                            fullName: mship.fullName,
                                            id: mship.user.id,
                                        },
                                    });
                                }}
                                returnMship
                            />
                        </Grid>

                        {/* --- Payment form --- */}
                        {price > 0 && (
                            <Grid item xs={4}>
                                <SttSelectPForm
                                    name="pForm"
                                    disabled={!!boleto.id}
                                    defVal={paymentForm}
                                    activePFs={[
                                        '',
                                        constants.payment.paymentForms.purse
                                            .name,
                                        constants.payment.paymentForms.cash
                                            .name,
                                        constants.payment.paymentForms.bank
                                            .name,
                                        cxt?.sc?.dataphoneConnected
                                            ? constants.payment.paymentForms
                                                  .dataphoneConnected.name
                                            : constants.payment.paymentForms
                                                  .dataphone.name,
                                        constants.payment.paymentForms.receipt
                                            .name,
                                        constants.payment.paymentForms.free
                                            .name,
                                    ].concat(
                                        CASHDRO
                                            ? [
                                                  constants.payment.paymentForms
                                                      .cashdro.name,
                                              ]
                                            : [],
                                    )}
                                    onChange={({ value }) =>
                                        setPaymentForm(value)
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={price > 0 ? 4 : 12}>
                            <SttFormDate
                                name="expiration"
                                caption={cxt.t('Expiration')}
                                disabled={!!boleto.id}
                                defVal={boleto.expiration}
                                onChange={setProperty}
                            />
                        </Grid>
                        {price > 0 && (
                            <Grid item xs={4}>
                                <SttFormText
                                    name="price"
                                    caption={cxt.t(translations.generic.price)}
                                    disabled={!!boleto.id}
                                    defVal={price}
                                    onChange={({ value }) => setPrice(value)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SttFormTextarea
                                name="notes"
                                caption={cxt.t(translations.generic.notes)}
                                defVal={boleto.notes}
                                onChange={setProperty}
                            />
                        </Grid>
                        {boleto.id && (
                            <>
                                <Grid item xs={12}>
                                    <Consumption
                                        caption={`${cxt.t('Consumed')} ${
                                            boleto.consumed
                                        } / ${boleto.amount}`}
                                        amount={boleto.amount}
                                        consumed={boleto.consumed}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box m={3}>
                                            <SttTable
                                                autoload={false}
                                                data={
                                                    movements?.count >
                                                    rowsPerPage
                                                        ? {
                                                              ...movements,
                                                              rows: movements?.rows.slice(
                                                                  0,
                                                                  rowsPerPage,
                                                              ),
                                                          }
                                                        : movements
                                                }
                                                localPagination={false}
                                                perPage={rowsPerPage}
                                                columns={[
                                                    {
                                                        title: cxt.t(
                                                            translations.bono
                                                                .movement,
                                                        ),
                                                        type: 'text',
                                                        value: (row) => (
                                                            <p
                                                                style={{
                                                                    color:
                                                                        row.amount >
                                                                        0
                                                                            ? colors.green
                                                                            : colors.red,
                                                                }}
                                                            >
                                                                {row.amount > 0
                                                                    ? cxt.t(
                                                                          translations
                                                                              .bono
                                                                              .refund,
                                                                      )
                                                                    : cxt.t(
                                                                          translations
                                                                              .bono
                                                                              .consumption,
                                                                      )}
                                                            </p>
                                                        ),
                                                    },
                                                    {
                                                        title: cxt.t(
                                                            translations.generic
                                                                .created,
                                                        ),
                                                        type: 'dmyhm',
                                                        field: 'created',
                                                    },
                                                    {
                                                        title: cxt.t(
                                                            translations.generic
                                                                .concept,
                                                        ),
                                                        type: 'text',
                                                        field: 'concept',
                                                    },
                                                    {
                                                        title: cxt.t(
                                                            translations.generic
                                                                .amount,
                                                        ),
                                                        type: 'text',
                                                        field: 'amount',
                                                    },
                                                    {
                                                        title: '',
                                                        value: (row) =>
                                                            row.amount < 0 && (
                                                                <ClearIcon
                                                                    className={
                                                                        classes.playButton
                                                                    }
                                                                    onClick={() =>
                                                                        refundBonoMov(
                                                                            row.id,
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                    },
                                                ]}
                                                onFetch={(response) =>
                                                    loadMovements(response)
                                                }
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
                buttons={[
                    boleto.id &&
                        boleto.payment &&
                        !boleto.trash && {
                            component: (
                                <SttDropdownButton
                                    icon={<CloudDownload />}
                                    caption={cxt.t(translations.generic.print)}
                                    options={[
                                        {
                                            label: 'A4',
                                            value: 0,
                                        },
                                        {
                                            label: 'Ticket',
                                            value: 1,
                                        },
                                    ]}
                                    size="medium"
                                    variant="outlined"
                                    color="default"
                                    onSelected={(opt) => openReceipt(opt.value)}
                                />
                            ),
                        },
                    !boleto.trash && {
                        type: 'save',
                        onClick: upsert,
                    },
                    boleto.id &&
                        !boleto.trash && {
                            type: 'delete',
                            onClick: () => setOpenAskingPaymentProcedure(true),
                        },
                    boleto.id &&
                        !boleto.trash && {
                            icon: <CheckOutlined />,
                            caption: cxt.t('Consume'),
                            onClick: consume,
                        },
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />

            <HandlePaymentsModal
                warningText={cxt.t('sc.boleto.delete.howToHandlePayments')}
                open={openAskingPaymentProcedure}
                onClose={() => setOpenAskingPaymentProcedure(false)}
                options={[
                    {
                        caption: cxt.t('associatedPayments.doNothing'),
                        value: '0',
                    },
                    {
                        caption: cxt.t('associatedPayments.refundToPurse'),
                        value: '1',
                    },
                    {
                        caption: cxt.t('associatedPayments.refundInCash'),
                        value: '2',
                    },
                    {
                        caption: cxt.t('associatedPayments.delete'),
                        value: '3',
                    },
                ]}
                onAccept={(option) => {
                    // JPB 9 Jul 2020. Deleting action codes in groups is different than in other in booking.
                    switch (option) {
                        case '0':
                            deleteBoleto();
                            break; // PBM: 'do nothing' case was missing
                        case '1':
                            deleteBoleto(1);
                            break;
                        case '2':
                            deleteBoleto(2);
                            break;
                        case '3':
                            deleteBoleto(3);
                            break;
                        default:
                    }
                }}
            />

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        saveAndClose();
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        saveAndClose();
                    }}
                />
            )}

            {cashdroPayment && (
                <CashdroControlDialog
                    open={cashdroPayment}
                    amount={cashdroPaymentAmount}
                    onClose={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                    }}
                    onSuccess={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                        createPayment();
                    }}
                />
            )}
        </>
    );
}
