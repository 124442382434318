import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttDialog,
    SttSelectMship,
    SttFormPrice,
    SttFormTextarea,
    SttSelectPForm,
} from '../../sporttia/all';
import { parsePrice, requestAdHoc } from '../../lib/utils';
import POSStripePaymentDialog from '../stripe/POSStripePaymentDialog';
import POSPaymentDialog from './POSPaymentDialog';
import Cashdro from '../../lib/Cashdro';
import constants from '../../config/constants';
import CashdroControlDialog from './CashdroControlDialog';

/**
 * Create payment
 *
 * Props
 *
 * 		* Object mship: mship por defecto
 * 		* Function onPaymentSuccess(payment)
 * 	    * String targetAPI (for example /bookings/1234/payments)
 */
export default function PaymentCreationDialog({
    open,
    mship,
    onClose,
    onPaymentSuccess,
    targetAPI,
}) {
    const cxt = useContext(AppContext);
    const [payment, setPayment] = useState({
        concept: '',
        price: '',
        mship: mship || { name: '', user: {} },
        paymentForm: '',
    });

    const [posPaymentParams, setPosPaymentParams] = useState(null);
    const [cashdroPayment, setCashdroPayment] = useState(false);
    const [cashdroPaymentAmount, setCashdroPaymentAmount] = useState(0);

    // TODO: config pool CASHDRO
    const CASHDRO = cxt?.sc?.id === 1516 || cxt?.sc?.id === 3444;

    useEffect(() => {
        setPayment({
            ...payment,
            mship,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mship]);

    /**
     * Handle form
     */
    const handleForm = ({ name, value }) => {
        setPayment({ ...payment, [name]: value });
    };

    /* Iniciar pago (puede quererse pagar con 'cashdro' / algún dispositivo físico antes de llamar a la API */
    const startPayment = async () => {
        if (
            !(
                payment &&
                payment.mship &&
                payment.mship.user &&
                payment.concept &&
                payment.price
            )
        ) {
            cxt.showMessage(
                'W',
                'Necesita especificar usuario, concepto y cantidad',
            );
            return;
        }

        if (
            CASHDRO &&
            payment.paymentForm === constants.payment.paymentForms.cashdro.name
        ) {
            setCashdroPayment(true);
            setCashdroPaymentAmount(payment.price);
        } else {
            await createPayment();
        }
    };

    /**
     * Create payment
     */
    const createPayment = async () => {
        cxt.api('POST', targetAPI || '/payments', {
            params: {
                concept: payment.concept,
                price: parsePrice(payment.price),
                idUser: payment?.mship?.user?.id || null,
                paymentForm: payment.paymentForm,
            },
            success: (response) => {
                cxt.showMessage('S', cxt.t('Created'));

                setPayment({
                    concept: '',
                    price: '',
                    mship: mship || { name: '', user: {} },
                    paymentForm: '',
                });

                if (
                    response?.payment?.tpv &&
                    response?.payment?.paymentForm?.toUpperCase() ===
                        'DATAFONO_CONNECTED'
                ) {
                    setPosPaymentParams({
                        amount: response.payment.price,
                        concept: response.payment.concept,
                        idTpv: response.payment.tpv.id,
                        sc: {
                            id: response.payment.tpv?.sc?.id,
                            scName: response.payment.tpv?.sc?.short,
                            entityName:
                                response.payment.tpv?.sc?.customer?.name,
                            address: response.payment.tpv?.sc?.address,
                            cif: response.payment.tpv?.sc?.customer?.cif,
                            phone: response.payment.tpv?.sc?.phonePublic,
                        },
                    });
                    return;
                }

                onPaymentSuccess?.(response.payment);
            },
        });
    };

    const closeDialog = () => {
        setPayment({
            concept: '',
            price: '',
            mship: mship || { name: '', user: {} },
            paymentForm: '',
        });

        onClose();
    };

    // -----| Render |-----
    return (
        <>
            <SttDialog
                title={cxt.t('New')}
                open={open}
                onClose={onClose}
                content={
                    <Grid container spacing={3}>
                        <SttSelectMship
                            mship={payment.mship}
                            onSelect={(selectedMship) =>
                                setPayment({ ...payment, mship: selectedMship })
                            }
                        />

                        <SttFormTextarea
                            grid
                            name="concept"
                            caption={cxt.t('Concept')}
                            defVal={payment.concept}
                            onChange={handleForm}
                        />

                        <SttFormPrice
                            grid
                            md={4}
                            name="price"
                            caption={cxt.t('Price')}
                            defVal={payment.price}
                            onChange={handleForm}
                        />

                        {/* --- Payment form --- */}
                        <SttSelectPForm
                            grid
                            md={8}
                            name="paymentForm"
                            caption={cxt.t('PaymentForm')}
                            defVal={payment.paymentForm}
                            onChange={handleForm}
                            activePFs={[
                                '',
                                'PURSE',
                                'CASH',
                                'BANK',
                                cxt?.sc?.dataphoneConnected
                                    ? 'DATAFONO_CONNECTED'
                                    : 'DATAFONO',
                                'RECEIPT',
                                'FREE',
                            ].concat(CASHDRO ? ['CASHDRO'] : [])}
                        />
                    </Grid>
                }
                buttons={[
                    {
                        caption: cxt.t('Create'),
                        type: 'accept',
                        onClick: () => startPayment(),
                        disabled: !(
                            payment &&
                            payment.mship &&
                            payment.mship.user
                        ),
                    },
                    {
                        type: 'close',
                        onClick: closeDialog,
                    },
                ]}
            />

            {/* stripePosPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={stripePosPaymentParams}
                    onClose={() => setStripePosPaymentParams(null)}
                    onSuccess={() => {
                        onPaymentSuccess?.();
                    }}
                />
            ) : null */}

            {cxt.sc?.sporttiaStripeLocationId && posPaymentParams !== null ? (
                <POSStripePaymentDialog
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        onPaymentSuccess?.();
                    }}
                />
            ) : (
                <POSPaymentDialog
                    open={posPaymentParams !== null}
                    paymentData={posPaymentParams}
                    onClose={() => setPosPaymentParams(null)}
                    onSuccess={() => {
                        onPaymentSuccess?.();
                    }}
                />
            )}

            {cashdroPayment && (
                <CashdroControlDialog
                    open={cashdroPayment}
                    amount={cashdroPaymentAmount}
                    onClose={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                    }}
                    onSuccess={() => {
                        setCashdroPayment(false);
                        setCashdroPaymentAmount(0);
                        createPayment();
                    }}
                />
            )}
        </>
    );
}
