import React, { useState, useEffect, useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { AppContext, SttTabs } from '../../../sporttia/all';
import ConfigurationMain from './ConfigurationMain';
import ConfigurationConsents from './ConfigurationConsents';
import ConfigurationRecharges from './ConfigurationRecharges';
import ConfigurationPrivileges from './ConfigurationPrivileges';
import ConfigurationNotifications from './ConfigurationNotifications';
import ConfigurationMap from './ConfigurationMap';
import ConfigurationBilling from './ConfigurationBilling';
import ConfigurationWeb from './ConfigurationWeb';
import ConfigurationMandatoryData from './ConfigurationMandatoryData';
import ConfigurationPOS from './ConfigurationPOS';
import ConfigurationCashdro from './ConfigurationCashdro';

export default function Configuration({}) {
    const cxt = useContext(AppContext);
    const [scModules, setSCModules] = useState();

    /**
     * Load sports center
     */
    const loadSportcenter = () => {
        cxt.api('GET', `/scs/${cxt.sc.id}`, {
            success: (r) => {
                setSCModules(r.sc.modules);
            },
        });
    };

    /**
     * Init
     */
    useEffect(() => {
        if (cxt.sc.modules) setSCModules(cxt.sc.modules);
        else loadSportcenter();

        cxt.setHeader({
            title: cxt.t('Configuration'),
        });
    }, []);

    // TODO: config pool CASHDRO
    const CASHDRO = cxt?.sc?.id === 1516 || cxt?.sc?.id === 3444;

    // -----| Render |-----
    return (
        <SttTabs
            tabs={[
                {
                    caption: cxt.t('Data'),
                    component: <ConfigurationMain />,
                },
                {
                    caption: cxt.t('Location'),
                    component: <ConfigurationMap />,
                },
                {
                    caption: cxt.t('Notifications'),
                    component: <ConfigurationNotifications />,
                },
                {
                    caption: cxt.t('Recharges'),
                    component: <ConfigurationRecharges />,
                },
                {
                    caption: cxt.t('Privileges'),
                    component: <ConfigurationPrivileges />,
                },
                {
                    caption: cxt.t('Consents'),
                    component: <ConfigurationConsents />,
                },
                {
                    caption: cxt.t('Billing'),
                    component: <ConfigurationBilling />,
                },
                {
                    show: scModules && scModules.includes('web'),
                    caption: 'Web',
                    component: <ConfigurationWeb />,
                },
                {
                    caption: cxt.t('MandatoryFields'),
                    component: <ConfigurationMandatoryData />,
                },
                {
                    show: scModules && scModules.includes('dataphoneConnected'),
                    caption: cxt.t('PF.DATAFONO'),
                    component: <ConfigurationPOS />,
                },
                {
                    show: CASHDRO,
                    caption: 'CashDro',
                    component: <ConfigurationCashdro />,
                },
            ]}
            grow
        />
    );
}
