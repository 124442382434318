import React, { useContext, useState } from 'react';
import { Grid, Box, TextField, Typography } from '@material-ui/core';
import { useMutation } from 'react-query';
import {
    AppContext,
    SttFormCheck,
    SttButtonAccept,
} from '../../../../sporttia/all';
import useScsService from '../../../../services/ScsService';
import { useLoader } from '../../../../lib/hooks';
/**
 * List with checkboxes to modify module states, services and center options.
 * @param sc Sport center object.
 * @param onUpdate Action carried out when updating the center by clicking on a checkbox.
 * @returns {JSX.Element}
 */
export default function AdminSCOptions({ sc, onUpdate }) {
    const cxt = useContext(AppContext);
    const scsService = useScsService();
    const updateMutation = useMutation(
        (params) => scsService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                onUpdate(data);
            },
            onError: (error) => {
                cxt.showMessage('E', error.error.msg);
            },
        },
    );
    const [stripeAccountId, setStripeAccountId] = useState(
        sc ? sc.sporttiaStripeConnectedAccount : null,
    );
    const [sporttiaStripeFee, setSporttiaStripeFee] = useState(
        sc ? sc.sporttiaStripeFee : null,
    );
    const [stripeLocationId, setStripeLocationId] = useState(
        sc ? sc.stripeLocationId : null,
    );
    const [, loader] = useLoader([updateMutation.status]);
    const extraModules = [
        'moduleGolf',
        'dataphoneConnected',
        'cashdroConnected',
    ];

    // Modules
    const modules = [
        { value: 'booking', label: 'Alquileres' },
        { value: 'activity', label: 'Actividades' },
        { value: 'purse', label: 'Monedero' },
        { value: 'receipt', label: 'Recibos' },
        { value: 'salePoint', label: 'P. venta' },
        { value: 'web', label: 'Web' },
        { value: 'device', label: 'Tornos' },
        { value: 'integration', label: 'Integración' },
        { value: 'moduleGolf', label: 'Golf' },
        { value: 'dataphoneConnected', label: 'Datáfono' },
        { value: 'cashdroConnected', label: 'Cashdro' },
    ];

    // Options
    const options = [
        { value: 'visible', label: 'Visible' },
        { value: 'showVAT', label: 'Mostrar IVA' },
        { value: 'generateFees', label: 'Generar cuotas automáticamente' },
        { value: 'SMSActive', label: 'Envio de SMS activo' },
        {
            value: 'TPVFeePayment',
            label: 'Pago de cuotas con tarjeta de crédito',
        },
    ];

    // Data fields
    const dataFields = [
        {
            value: 'sporttiaStripeConnectedAccount',
            label: 'Id de la cuenta conectada',
            size: 4,
            update: (value) => setStripeAccountId(value),
            get: () => stripeAccountId,
        },
        {
            value: 'sporttiaStripeFee',
            label: 'Comisión para Sporttia',
            size: 4,
            update: (value) => setSporttiaStripeFee(value),
            get: () => sporttiaStripeFee,
        },
        {
            value: 'sporttiaStripeLocationId',
            label: 'Id del location',
            size: 4,
            update: (value) => setStripeLocationId(value),
            get: () => stripeLocationId,
        },
    ];

    /**
     * Save sports center.
     * @param type ['module'|'option'|'service'] options.
     * @param name Module, option or service name.
     * @param value Value.
     */
    const saveSC = (type, name, value, updatedDataFields = null) => {
        const params = {};
        // Save module
        if (type === 'module') {
            params.modules = sc.modules ? [...sc.modules] : [];

            if (!extraModules.includes(name)) {
                if (value) {
                    params.modules.push(name);
                } else {
                    params.modules.splice(params.modules.indexOf(name), 1);
                }
            } else {
                // Guardo el valor de moduleGolf si existe ya que este no está dentro de sc.modules
                if (name === 'moduleGolf') {
                    params.moduleGolf = value;
                }
                if (name === 'dataphoneConnected') {
                    params.dataphoneConnected = value;
                }
                if (name === 'cashdroConnected') {
                    params.cashdroConnected = value;
                }
            }
        }

        // Save option
        if (type === 'option') {
            params[name] = value;
        }

        // Save dataFields

        if (type === 'dataField') {
            updatedDataFields.forEach((updatedDataField) => {
                params[updatedDataField.name] = updatedDataField.value;
            });
        }

        // Save service
        if (type === 'service') {
            params.services = [...sc.services].map((e) => e.id);

            if (value) {
                params.services.push(name);
            } else {
                params.services.splice(params.services.indexOf(name), 1);
            }
        }

        updateMutation.mutate({ id: sc?.id, params });
    };

    function checkModuleState(module) {
        let moduleState = false;

        if (extraModules.includes(module.value)) {
            moduleState = sc[module.value];
        } else if (sc.modules) {
            moduleState = sc.modules.includes(module.value);
        }

        return moduleState;
    }

    return (
        <>
            {loader}
            {sc && (
                <Grid container spacing={3}>
                    {/* --- Options --- */}
                    <Grid item md={4}>
                        {options.map((option) => (
                            <Box key={option.value}>
                                <SttFormCheck
                                    name={option.value}
                                    caption={option.label}
                                    checked={option?.value && sc[option.value]}
                                    onChange={({ name, value }) =>
                                        saveSC('option', name, value)
                                    }
                                />
                            </Box>
                        ))}
                        <Grid item style={{ marginTop: '8px' }}>
                            <Typography
                                style={{ fontSize: '18px', fontWeight: 'bold' }}
                            >
                                Configuración de Stripe
                            </Typography>
                        </Grid>
                        {dataFields.map((dataField) => (
                            <Grid
                                key={dataField.value}
                                container
                                spacing={2}
                                direction="row"
                            >
                                <Grid item>
                                    <Typography style={{ marginTop: '8px' }}>
                                        {dataField.label}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    style={{ textAlign: 'center' }}
                                    md={dataField.size}
                                >
                                    <TextField
                                        key={dataField.value}
                                        id="standard-basic"
                                        variant="standard"
                                        defaultValue={
                                            sc[dataField.value]
                                                ? sc[dataField.value]
                                                : ''
                                        }
                                        onChange={(event) => {
                                            dataField.update(
                                                event.target.value,
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item style={{ marginTop: '12px' }}>
                            <SttButtonAccept
                                caption="Guardar configuraciones de Stripe"
                                onClick={() => {
                                    const updatedDataFields = dataFields.map(
                                        (dataField) => ({
                                            name: dataField.value,
                                            value: dataField.get(),
                                        }),
                                    );
                                    saveSC(
                                        'dataField',
                                        null,
                                        null,
                                        updatedDataFields,
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* --- Modules --- */}
                    <Grid item md={4}>
                        {modules.map((module) => (
                            <Box key={module.value}>
                                <SttFormCheck
                                    name={module.value}
                                    caption={module.label}
                                    checked={checkModuleState(module)}
                                    onChange={({ name, value }) =>
                                        saveSC('module', name, value)
                                    }
                                />
                            </Box>
                        ))}
                    </Grid>

                    {/* --- Services --- */}
                    <Grid item md={4}>
                        {cxt.constants.services.map((service) => (
                            <Box key={service.id}>
                                <SttFormCheck
                                    name={service.id}
                                    caption={service.label}
                                    checked={
                                        sc.services.find(
                                            (e) => e.id === service.id,
                                        ) !== undefined
                                    }
                                    onChange={({ name, value }) =>
                                        saveSC('service', name, value)
                                    }
                                />
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
}
