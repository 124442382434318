import { Box, Grid, TextField } from '@material-ui/core';
import { SttButton } from '../../../sporttia/buttons/SttButton';
import { Save } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../sporttia/all';

export default function ConfigurationCashdro(props) {
    const [ip, setIp] = useState(localStorage.getItem('CashdroIp'));

    const cxt = useContext(AppContext);

    function setIpValue(ip) {
        setIp(ip);
    }

    function saveIp() {
        cxt.showMessage('S', cxt.t('Saved'));
        localStorage.setItem('CashdroIp', ip);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label={'Ip del dispositivo'}
                    // focused={forceInputFocus}
                    size="small"
                    value={ip}
                    onChange={(e) => {
                        setIpValue(e.target.value);
                    }}
                    // onKeyPress={keyPressed}
                    // helperText={}
                    // error={error}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SttButton
                    startIcon={<Save />}
                    caption={cxt.t('Save')}
                    color="primary"
                    variant="contained"
                    // disabled={testing}
                    onClick={() => {
                        saveIp();
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box>
                    {
                        'Por ejemplo: 192.168.88.24; Consulte este valor en el panel de control del CashDro o en la configuración de red.'
                    }
                </Box>
            </Grid>
        </Grid>
    );
}
